
export default {
  props: {
    item: {
      required: true,
      type: Object
    }
  },

  computed: {
    price () {
      return (this.item.price + this.item.warranties.reduce((total, w) => total + w.price, 0) + this.item.addons.reduce((total, a) => total + a.price, 0))
    },

    total () {
      return this.item.quantity * this.price
    },

    discount () {
      if (this.item.coupon === null) {
        return 0
      }

      if (this.item.coupon.discount.type.type === 'percent') {
        return Math.round(this.price * this.item.coupon.discount.percent / 100)
      } else {
        return this.item.coupon.discount.amount.amount
      }
    },

    totalDiscount () {
      return this.item.quantity * this.discount
    },

    totalWithDiscount () {
      return this.total - this.discount
    }
  }
}
