
import { mapGetters } from 'vuex'

export default {
  props: {
    disabled: {
      required: false,
      default () {
        return false
      },
      type: Boolean
    }
  },

  computed: {
    ...mapGetters({
      logo: 'logo',
      store: 'store'
    })
  }
}
