
import { mapGetters } from 'vuex'

import Breadcrumbs from '@/components/Breadcrumbs'
import Share from '@/components/journal/Share'
import JournalArticle from '@/components/index/JournalArticle'

export default {
  components: {
    Breadcrumbs,
    Share,
    JournalArticle
  },

  props: {
    article: {
      type: Object,
      default () {
        return {}
      },
      required: true
    }
  },

  data: () => ({
    views: undefined,
    articles: {
      similar: []
    }
  }),

  computed: {
    ...mapGetters({
      country: 'country',
      currency: 'currency'
    }),

    breadcrumbs () {
      this.article.breadcrumbs.map((crumb, index) => {
        crumb.to = this.localePath({ name: 'slug', params: { slug: crumb.slug } })
        crumb.active = false
      })

      return [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        }
      ].concat(this.article.breadcrumbs)
    },

    publishedAt () {
      const now = this.$moment().locale(this.$i18n.locale)
      const publishedAt = this.$moment(this.article.published_at).locale(this.$i18n.locale)

      if (publishedAt.date() === now.date() &&
        publishedAt.month() === now.month() &&
        publishedAt.year() === now.year()
      ) {
        return publishedAt.format('HH:mm')
      } else if (publishedAt.year() === now.year()) {
        return publishedAt.format('DD MMM, HH:mm')
      } else {
        return publishedAt.format('lll')
      }
    }
  },

  async mounted () {
    this.loadViewsArticles()
    await this.loadSimilarArticles()
  },

  methods: {
    loadViewsArticles () {
      const params = {
        credentials: true
      }

      this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/journal/articles/${this.article.id}/views`, params)
        .then((res) => {
          this.views = res.data
        })
        .catch((error) => {
          console.log(error)
        })
        // .finally(() => {
        //   //
        // })
    },

    async loadSimilarArticles () {
      await this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/journal/articles/${this.article.id}/similar`)
        .then((res) => {
          this.articles.similar = res.data
        })
        .catch((error) => {
          console.log(error)
        })
        // .finally(() => {
        //   //
        // })
    }
  },

  head () {
    return {
      htmlAttrs: {
        prefix: 'og: http://ogp.me/ns#'
      },
      meta: [
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.article.image.lazy
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'article'
        }
      ]
    }
  }
}
