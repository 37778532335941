
import { mapGetters } from 'vuex'

import { mixin as clickaway } from 'vue-clickaway'

// import 'vue-slider-component/dist-css/vue-slider-component.css'
import VueSlider from 'vue-slider-component/dist-css/vue-slider-component.umd.min'

import Breadcrumbs from '@/components/Breadcrumbs'
import Product from '@/components/product/Product'
import Trigger from '@/components/category/Trigger'

export default {
  components: {
    Breadcrumbs,
    Product,
    Trigger,
    VueSlider
  },

  mixins: [
    clickaway
  ],

  props: {
    category: {
      type: Object,
      default () {
        return {}
      },
      required: true
    }
  },

  data: () => ({
    sort: {
      by: null,
      active: false,
      options: {
        popularity: {
          id: 1,
          translation: 'popularity',
          value: 'popularity',
          icon: ['fas', 'fire']
        },
        priceasc: {
          id: 2,
          translation: 'price.asc',
          value: 'priceasc',
          icon: ['fas', 'arrow-down-1-9']
        },
        pricedesc: {
          id: 3,
          translation: 'price.desc',
          value: 'pricedesc',
          icon: ['fas', 'arrow-down-9-1']
        }
      }
    },
    totalFilterRoles: [
      'series'
    ],
    badgesFilterKey: 'badges',
    priceFilterKey: 'price',
    sortFilterKey: 'sort',
    priceInputChanged: false,
    page: 1,
    loading: false,
    filtering: false,
    params: '',
    filters: [],
    chosenFilters: [],
    filtersTouchScreen: true,
    filtersTouchScreenActive: false,
    reachedLastPage: false,
    isMounted: false
  }),

  computed: {
    ...mapGetters({
      country: 'country',
      currency: 'currency'
    }),

    breadcrumbs () {
      const length = this.category.breadcrumbs.length

      this.category.breadcrumbs.map((crumb, index) => {
        crumb.to = this.localePath({ name: 'slug', params: { slug: crumb.slug } })
        crumb.active = this.chosenFilters.length > 0 ? false : length === (index + 1)
      })

      const breadcrumbs = [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        }
      ].concat(this.category.breadcrumbs)

      this.chosenFilters.forEach((chosenFilter) => {
        if ([this.badgesFilterKey, this.priceFilterKey, this.sortFilterKey].includes(chosenFilter.key)) {
          return
        }

        chosenFilter.values.forEach((value) => {
          const path = this.localePath({
            name: 'slug-params',
            params: {
              slug: this.category.page.slug.split('/')[0],
              params: `${chosenFilter.slug}--${value.slug}`
            }
          })

          breadcrumbs.push({
            title: value.title,
            to: path,
            active: this.$route.path === path
          })
        })
      })

      return breadcrumbs
    },

    chosenFiltersLength () {
      return this.chosenFilters.map(filter => filter.values).flat().length
    }

    // pagination () {
    //   const pagination = {
    //     first: {
    //       page: 1,
    //       isActive: this.page === 1,
    //       component: this.page === 1 ? 'span' : 'nuxt-link',
    //       to: this.page === 1
    //         ? null
    //         : `${this.localePath({
    //             name: 'slug',
    //             params: { slug: this.category.page.slug }
    //           })}`
    //     },
    //     last: {
    //       page: this.category.pages,
    //       isActive: this.page === this.category.pages,
    //       component: this.page === this.category.pages ? 'span' : 'nuxt-link',
    //       to: this.page === this.category.pages
    //         ? null
    //         : `${this.localePath({
    //             name: 'slug',
    //             params: { slug: this.category.page.slug }
    //           })}?page=${this.category.pages}`
    //     }
    //   }

    //   if (this.page > 1) {
    //     const previous = this.page - 1

    //     pagination.previous = {
    //       page: previous,
    //       isActive: false,
    //       component: 'nuxt-link',
    //       to: `${this.localePath({
    //         name: 'slug',
    //         params: { slug: this.category.page.slug }
    //       })}${previous > 1 ? `?page=${previous}` : ''}`
    //     }
    //   }

    //   if (this.page < this.category.pages) {
    //     const next = this.page + 1

    //     pagination.next = {
    //       page: next,
    //       isActive: false,
    //       component: 'nuxt-link',
    //       to: `${this.localePath({
    //         name: 'slug',
    //         params: { slug: this.category.page.slug }
    //       })}?page=${next}`
    //     }
    //   }

    //   return pagination
    // }
  },

  created () {
    this.reachedLastPage = this.page >= this.category.pages

    this.pushGtmViewItemListEvent(this.category.products)

    if (this.$route.params.params) {
      this.params = this.$route.params.params

      this.params.split('_').forEach((param) => {
        const values = param.split('--')
        const characteristicSlug = values.shift()

        const characteristicIndex = this.category.filters.findIndex(characteristic => characteristic.slug === characteristicSlug)

        if (characteristicIndex !== -1) {
          const filter = {
            key: characteristicSlug,
            values
          }

          const chosenFilter = {
            slug: this.category.filters[characteristicIndex].slug,
            title: `${this.category.filters[characteristicIndex].title}:`,
            values: []
          }

          // let title = ''

          values.forEach((slug, index, values) => {
            const valueIndex = this.category.filters[characteristicIndex].values.findIndex(value => value.slug === slug)

            if (valueIndex !== -1) {
              // if (process.server && this.category.class === 'Category') {
              //   title += (` ${this.category.filters[characteristicIndex].values[valueIndex].title}` + (index !== values.length - 1 ? ',' : ''))
              // }

              chosenFilter.values.push({
                slug: this.category.filters[characteristicIndex].values[valueIndex].slug,
                title: this.category.filters[characteristicIndex].values[valueIndex].title
              })

              this.category.filters[characteristicIndex].values[valueIndex].active = true
            }
          })

          // if (this.category.class === 'Category') {
          //   this.category.title = this.category.title + title
          // }

          this.filters.push(filter)
          this.chosenFilters.push({ ...filter, ...chosenFilter })
        }

        if (characteristicSlug === this.badgesFilterKey) {
          const filter = {
            key: characteristicSlug,
            values
          }

          this.filters.push(filter)

          values.forEach((value) => {
            const badgeIndex = this.category.badges.findIndex(badge => badge.slug === value)

            if (badgeIndex !== -1) {
              this.category.badges[badgeIndex].active = true
            }
          })
        }

        if (characteristicSlug === this.priceFilterKey) {
          const filter = {
            key: characteristicSlug,
            values: [
              values.join('--')
            ]
          }

          this.filters.push(filter)

          const chosenFilter = {
            slug: this.priceFilterKey,
            title: `${this.$t('product.filter.price.title', { currency: this.currency.sign })}:`,
            values: [
              {
                slug: values.join('--'),
                title: values.join('—')
              }
            ]
          }

          this.chosenFilters.push({ ...filter, ...chosenFilter })
        }

        if (characteristicSlug === this.sortFilterKey) {
          const filter = {
            key: characteristicSlug,
            values
          }

          this.filters.push(filter)

          this.sort.by = this.sort.options[values[0]]

          const chosenFilter = {
            slug: this.sortFilterKey,
            title: '',
            values: [
              {
                slug: values.join(' '),
                title: this.$t('category.sort.by', { by: this.$t(`category.sort.options.${this.sort.options[values[0]].translation}`) })
              }
            ]
          }

          this.chosenFilters.push({ ...filter, ...chosenFilter })
        }
      })
    }

    const findIndex = this.filters.findIndex(f => f.key === 'sort')

    if (findIndex === -1) {
      this.sort.by = this.sort.options[Object.keys(this.sort.options)[0]]
    }

    this.page = this.$route.query.page ? Number(this.$route.query.page) : 1
  },

  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.resizeWindow, { passive: true })
    })

    this.isMounted = true

    this.category.filters.forEach((filter) => {
      this.$refs[`filter[${filter.id}]`][0].style.height = this.calculateFieldHeight(filter)
    })
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.resizeWindow)
  },

  methods: {
    chosenFilterComponent (ref) {
      if (this.$refs[ref] === undefined) {
        return false
      }

      return this.$refs[ref][0].clientWidth < this.$refs[ref][0].scrollWidth
    },

    calculateFieldHeight (filter) {
      if (this.$refs[`filter[${filter.id}]`] === undefined || this.$refs[`filter[${filter.id}]`][0] === undefined) {
        return false
      }

      const children = [...this.$refs[`filter[${filter.id}]`][0].children]
      let height = 0

      children.splice(0, filter.max_filter_values).forEach((filter) => {
        height += filter.clientHeight
      })

      return `${height}px`
    },

    toggleFiltersTouchScreen () {
      this.filtersTouchScreenActive = !this.filtersTouchScreenActive

      this.$nextTick(() => {
        this.category.filters.forEach((filter) => {
          this.$refs[`filter[${filter.id}]`][0].style.height = this.calculateFieldHeight(filter)
        })
      })
    },

    hideSortOptions () {
      this.sort.active = false
    },

    isTouchScreen () {
      return document.documentElement.clientWidth <= 991
    },

    resizeWindow () {
      this.filtersTouchScreen = this.isTouchScreen()

      this.category.filters.forEach((filter) => {
        this.$refs[`filter[${filter.id}]`][0].style.height = this.calculateFieldHeight(filter)
      })
    },

    getFilterCount (filter, value) {
      const chosenFilterValue = this.chosenFilters.findIndex(cf => cf.key === filter.slug && cf.values.findIndex(v => v.slug === value.slug) >= 0) >= 0
      const totalFilterRole = this.totalFilterRoles.includes(filter.role)

      if (this.chosenFilters.length && chosenFilterValue && totalFilterRole && value.total > 0) {
        return value.total
      } else if (this.chosenFilters.length && !chosenFilterValue && totalFilterRole && value.total > 0) {
        return `+${value.total}`
      } else {
        return value.count
      }
    },

    clickOnFilter (event, characteristic, value) {
      this.checkFilterOnLinkClick(event)

      this.category.filters.find(filter => filter.id === characteristic.id).values.find(v => v.slug === value.slug).active = !value.active
    },

    changeFiltersArray (characteristic, value) {
      const index = this.filters.findIndex(f => f.key === characteristic)

      if (index !== -1 && value.active) {
        this.filters[index].values.push(value.slug)
      } else if (index !== -1 && !value.active) {
        const valueIndex = this.filters[index].values.findIndex(f => f === value.slug)

        if (valueIndex !== -1) {
          this.filters[index].values.splice(valueIndex, 1)
        }

        if (this.filters[index].values.length === 0) {
          this.filters.splice(index, 1)
        } else {
          this.filters[index].values.sort((a, b) => a.localeCompare(b))
        }
      } else {
        this.filters.push({
          key: characteristic,
          values: [
            value.slug
          ]
        })
      }

      this.filters.sort((a, b) => a.key.localeCompare(b.key))

      this.applyFilters()
    },

    priceInputKeydown (e) {
      if (e.keyCode === 13/* enter key */) {
        this.applyPriceInput()
      } else if ([8, 38, 40, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57].includes(e.keyCode)) {
        /* numbers, arrows, del, backspace keys */
        this.priceInputChanged = true
      }
    },

    applyPriceInput () {
      this.priceInputChanged = false

      this.category.prices.range[0] = this.$refs['min-price-input'].value
      this.category.prices.range[1] = this.$refs['max-price-input'].value

      const range = [
        parseInt(this.category.prices.range[0]),
        parseInt(this.category.prices.range[1])
      ]

      if (range[0] < this.category.prices.min) {
        range[0] = this.category.prices.min
      }

      if (range[0] > this.category.prices.max) {
        range[0] = this.category.prices.max
      }

      if (range[1] > this.category.prices.max) {
        range[1] = this.category.prices.max
      }

      if (range[0] > range[1]) {
        range[1] = range[0]
      }

      this.category.prices.range = range

      this.$refs['min-price-input'].value = this.category.prices.range[0]
      this.$refs['max-price-input'].value = this.category.prices.range[1]

      this.changePriceRange()
    },

    changePriceRange () {
      const index = this.filters.findIndex(f => f.key === this.priceFilterKey)

      const filter = {
        key: this.priceFilterKey,
        values: [
          parseInt(this.category.prices.range[0]),
          parseInt(this.category.prices.range[1])
        ]
      }

      if (index !== -1) {
        this.filters[index] = filter
      } else {
        this.filters.push(filter)
      }

      this.applyFilters()
    },

    changeSort (by) {
      this.sort.by = by

      const index = this.filters.findIndex(f => f.key === this.sortFilterKey)

      const filter = {
        key: this.sortFilterKey,
        values: [
          this.sort.by.value
        ]
      }

      const defaultSortKey = Object.keys(this.sort.options)[0]

      if (index !== -1 && this.sort.by.value !== defaultSortKey) {
        this.filters[index] = filter
      } else if (index === -1 && this.sort.by.value !== defaultSortKey) {
        this.filters.push(filter)
      } else if (index !== -1 && this.sort.by.value === defaultSortKey) {
        this.filters.splice(index, 1)
      }

      this.applyFilters()
    },

    applyFilters () {
      this.params = ''

      const badgesFilterIndex = this.filters.findIndex(f => f.key === this.badgesFilterKey)

      // Now move badges at the end of the array
      if (badgesFilterIndex !== -1) {
        this.filters.push(this.filters.splice(badgesFilterIndex, 1)[0])
      }

      const priceFilterIndex = this.filters.findIndex(f => f.key === this.priceFilterKey)

      // Now move price at the end of the array
      if (priceFilterIndex !== -1) {
        this.filters.push(this.filters.splice(priceFilterIndex, 1)[0])
      }

      const sortFilterIndex = this.filters.findIndex(f => f.key === this.sortFilterKey)

      // Now move sort at the end of the array
      if (sortFilterIndex !== -1) {
        this.filters.push(this.filters.splice(sortFilterIndex, 1)[0])
      }

      this.filters.forEach((filter, index, filters) => {
        let filterValues = ''

        filter.values.forEach((value, index, values) => {
          filterValues += `${value}${(index !== values.length - 1 ? '--' : '')}`
        })

        this.params += `${filter.key}--${filterValues}${(index !== filters.length - 1 ? '_' : '')}`
      })

      this.$router.replace(this.getPath(this.params))

      this.showOverlay()
    },

    async loadMoreProducts () {
      if (this.page >= this.category.pages) {
        return
      }

      this.page += 1
      this.loading = true

      const category = `category--${this.$route.params.slug}` + (this.params.length ? '_' : '')

      await this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/products/${category}${this.params}?page=${this.page}`)
        .then((res) => {
          this.reachedLastPage = this.page === this.category.pages
          this.category.products = [...this.category.products, ...res.data]

          this.pushGtmViewItemListEvent(res.data)

          // history.replaceState({}, null, `${window.location.origin}${window.location.pathname}?page=${this.page}`)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },

    checkFilterOnLinkClick (event) {
      const element = event.target.closest('.is-checkbox').getElementsByClassName('checkbox-filter')[0]
      element.checked = !element.checked

      this.showOverlay()
    },

    showOverlay () {
      this.filtering = true
    },

    getPath (params) {
      if (params) {
        return this.localePath({
          name: 'slug-params',
          params: {
            slug: this.$route.params.slug,
            params
          }
        })
      } else {
        return this.localePath({
          name: 'slug',
          params: {
            slug: this.$route.params.slug
          }
        })
      }
    },

    toggleAccordion (event) {
      const matches = document.querySelectorAll('.accordion-item-head')
      const isActive = event.target.classList.contains('active')

      for (let i = 0; i < matches.length; i++) {
        matches[i].classList.remove('active')
      }

      if (!isActive) {
        event.target.classList.add('active')
      }
    },

    pushGtmViewItemListEvent (products) {
      this.$gtm.push({ ecommerce: null }) // Clear the previous ecommerce object.
      this.$gtm.push({
        event: 'view_item_list',
        ecommerce: {
          items: products.map((product, index) => {
            return {
              item_name: product.title,
              item_id: product.store.id,
              price: product.store.price.current.toFixed(2),
              item_brand: product.brand,
              item_category: product.breadcrumbs.length ? product.breadcrumbs[0].title : '',
              item_category2: product.breadcrumbs.length ? product.breadcrumbs[product.breadcrumbs.length - 1].title : '',
              item_variant: product.variations.map(variation => variation.values.find(value => value.product_id === product.id)?.title).join(' '),
              item_list_name: this.category.title,
              item_list_id: this.category.id,
              index: index + 1,
              quantity: 1
            }
          })
        }
      })
    }
  },

  head () {
    const head = {}

    if (this.category.products.length > 0) {
      const product = this.category.products[0]

      head.meta = [
        {
          hid: 'og:image',
          property: 'og:image',
          content: `${product.images[0].folder}/page/lazy/${product.images[0].url}`
        }
      ]
    }

    return head
  }
}
