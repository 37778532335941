
import { mapGetters } from 'vuex'

import Breadcrumbs from '@/components/Breadcrumbs'

export default {
  components: {
    Breadcrumbs
  },

  props: {
    content: {
      type: Object,
      default () {
        return {}
      },
      required: true
    }
  },

  data: () => ({

  }),

  computed: {
    ...mapGetters({
      currency: 'currency'
    }),

    breadcrumbs () {
      return [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        },
        {
          title: this.content.page.header,
          to: '#',
          active: true
        }
      ]
    }
  }
}
